import axios from "axios";
import { useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import "./login.css";
import React from "react";
import { toast } from "react-toastify";


export default function Login() {
  const userRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const { dispatch, isFetching } = useContext(Context);

  const generateTempUsername = (name) => {
    const formattedName = name.toLowerCase().replace(/\s/g, "");
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;
    const tempUsername = `${formattedName}${randomNumber}`;

    return tempUsername;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/auth/login", {
        email: userRef.current.value,
        password: passwordRef.current.value,
      });

      if (res.status !== 200) {
        toast.info(res.data.msg);
      }

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/");
    } catch (err) {
      console.log(err);
      toast.error("Something went Wrong!");
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  const googleLogin = async (credentialResponse) => {
    dispatch({ type: "LOGIN_START" });

    try {
      const { credential } = credentialResponse;
      const decoded = jwtDecode(credential);

      // Extract user info from the decoded JWT token
      const { email, name, picture } = decoded;

      const res = await axios.post("/auth/google", {
        user_name: name,
        username: generateTempUsername(name),
        email,
        profilePic: picture,
        password: "Admin@123",
      });

      if (res.status !== 200) {
        toast.info(res.data.msg);
      }

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/");
    } catch (err) {
      toast.error("Something went Wrong!");
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  return (
    <div className="page-container">
      <div className="left-container">
        <Link to="/">
          <img
            src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/logo1.png"}
            alt="For Love of Writers Logo"
            className="logo-login"
          />
        </Link>
        <img
          src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/bottom_image.jpg"}
          alt="Your Image"
          className="picture"
        />
      </div>
      <div className="right-container-login">
        {/* <Header/> */}
        <form onSubmit={handleSubmit} className="form-login">
          <h1
            style={{
            }}
          >
            Log In !
          </h1>
          <div className="input-container-login">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              required
              ref={userRef}
              className="input-login"
            />

            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              required
              ref={passwordRef}
              className="input-login"
            />
            <div className="remember-me-login" style={{ display: "flex" }}>
              <input
                type="checkbox"
                className="input-login"
                style={{ width: "19px", height: "19px" }}
              />
              <p>
                Remember Me
              </p>
              <Link
                to={"/forgot-password"}
                className="linkClass"
              >
                Forgot Password?
              </Link>
            </div>
            <div style={{ justifyContent: "center", paddingTop: "20px" }}>
              <button type="submit" disabled={isFetching} className="login-btn">
                Log In
              </button>
            </div>
          </div>

          <p>
            Don't have an account?{" "}
            <Link to="/register" style={{ textDecoration: "none" }}>
              {" "}
              Sign Up
            </Link>
          </p>
          <div className="separator-login">
            <hr />
            <span>OR</span>
            <hr />
          </div>
          <div className="button-row">
            {/* <button className="socialButton google">
              <img src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/google_logo.png"} alt="Google Logo" />
            </button> */}

            <div style={{ justifyContent: "center", paddingTop: "20px" }}>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin
                  shape="rectangular"
                  theme="filled_blue"
                  onSuccess={googleLogin}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
