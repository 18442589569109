import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./authors.css";
import axios from "axios";
import React from "react";
import { decodeHtml } from "../../utils/utils";
import { toast } from "react-toastify";
const PF = process.env.REACT_APP_AWS_S3_IMAGE_URL;

export default function Authors() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 12;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get("/users");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const authors = res.data.filter((user) => user.isAuthor);
        setUsers(authors);
      } catch (err) {
        console.error("Error fetching writers! " + err);
        toast.error("Error Fetching Writers!");
      }
    };

    getUsers();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    window.scroll(0, 0);
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    window.scroll(0, 0);
  };

  return (
    <>
      <div className="header-container">
        <h1 className="header-title-authors">Writers</h1>

        {/* Currently We Don't Need that */}
        {/* <button className="filter-btn">
          Filter{" "}
          <FontAwesomeIcon icon={faFilter} style={{ marginLeft: "20px" }} />
        </button> */}
      </div>

      <div className="card-container-authorInfo">
        {currentPosts.map((user, index) => (
          <div className="card-authorInfo" key={index}>
            <div className="image-container-authorInfo">
              <Link to={`/author/${user.userId}`}>
                <img
                  src={
                    user.avatar_urls["full"] == null
                      ? user.profilePic.startsWith(
                          "https://myflowimages.s3.us-east-2.amazonaws.com"
                        )
                        ? user.profilePic
                        : user.profilePic + "&font-size=0.33"
                      : PF + user.avatar_urls["full"]
                  }
                  alt={user.user_name + "'s profile"}
                />
                <div className="caption-authorInfo">
                  {decodeHtml(user.user_name)}
                </div>
              </Link>
            </div>
            <div className="card-content">
              {/* <h2>Card Title {index + 1}</h2>
            <p>Card description goes here.</p> */}
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          style={{
            width: "50px",
            height: "30px",
            backgroundColor: "#B1E5F2",
            borderRadius: "10px",
            border: "none",
            cursor: "pointer",
          }}
          className="pagination-left-btn"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <button
          style={{
            width: "50px",
            height: "30px",
            backgroundColor: "#B1E5F2",
            borderRadius: "10px",
            border: "none",
            cursor: "pointer",
          }}
          className="pagination-right-btn"
          onClick={nextPage}
          disabled={currentPosts.length < postsPerPage}
        >
          Next
        </button>
      </div>
    </>
  );
}
