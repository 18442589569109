import axios from "axios";
import { Context } from "../../../context/Context";
import "./userProfile.css";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

const UserProfile = ({ onSave }) => {
  const { user } = useContext(Context);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const getUserData = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setFormData(res.data);
      } catch (error) {
        console.log("Error fetching Form Data.", error);
        toast.error("Error Fetching User Data");
      }
    };
    getUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      onSave(formData);
      if (formData.oldPassword && formData.newPassword) {
        const res = await axios.put(`/auth/resetPassword`, {
          email: formData.email,
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        });
        if (res.status === 200) {
          toast.success("Password Updated Successfully!");
        } else {
          toast.error("Error updating password!")
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    // <div className="form-section" id="user-info-form">
    <div className="UserProfileForm">
      <form>
        <label htmlFor="full-name">
          Full Name<span className="required">*</span>
        </label>
        <input
          type="text"
          id="full-name"
          name="user_name"
          placeholder="Enter your Full Name"
          value={formData.user_name}
          onChange={handleChange}
        />

        <label htmlFor="username">
          Username<span className="required">*</span>
        </label>
        <input
          type="text"
          id="username"
          name="username"
          placeholder="Enter your Username"
          value={formData.username}
          onChange={handleChange}
        />

        <label htmlFor="email">
          Email Address<span className="required">*</span>
        </label>
        <input
          type="text"
          id="email "
          name="email"
          placeholder="Enter your Email Address"
          required
          value={formData.email}
          onChange={handleChange}
        />

        <label htmlFor="password">
          Old Password<span className="required">*</span>
        </label>
        <input
          type="password"
          id="old-password"
          name="old-password"
          value={formData.oldPassword}
          onChange={handleChange}
        />

        <label htmlFor="confirm">
          New Password<span className="required">*</span>
        </label>
        <input
          type="password"
          id="new-password"
          name="new-password"
          value={formData.newPassword}
          onChange={handleChange}
        />

        <div className="form-row">
          <div className="input-container-UserProfile">
            <label htmlFor="dob">Date of Birth</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div className="input-container-UserProfile">
            <label htmlFor="gender" style={{ marginBottom: "10px" }}>
              Gender
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              style={{outline:"none", border:"none"}}
            >
              <option value="" disabled>
      Select
    </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="input-container-UserProfile">
            <label htmlFor="pronoun">Pronouns</label>
            <input
              type="text"
              id="pronoun"
              name="pronouns"
              value={formData.pronouns}
              onChange={handleChange}
            />
          </div>

          <div className="input-container-UserProfile">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>

      <button
        type="submit"
        className="save-button"
        style={{
          width: "212px",
          height: "67px",
          marginLeft: "550px",
          marginTop: "30px",
          fontSize: "28px",
          borderRadius:"10px"
        }}
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default UserProfile;
