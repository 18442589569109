import { useEffect, useState, useRef } from "react";
import Posts from "../../components/posts/Posts";
import "./articles.css";
import axios from "axios";
import { useLocation } from "react-router";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function Articles() {
  const [posts, setPosts] = useState([]);
  const [url, setUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [recentlyPublished, setRecentlyPublished] = useState(false);
  const [oldestArticles, setOldestArticles] = useState(false);
  const [azSort, setAzSort] = useState(false);
  const [zaSort, setZaSort] = useState(false);
  const filterDialogRef = useRef(null);
  const filterButtonRef = useRef(null);
  const postsPerPage = 12;

  const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await axios.get("/posts" + (url || search));

        if (res.status == 200) {
          const posts = res.data.filter((post) => post.status !== "draft");
          let sortedPosts = posts.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setPosts(sortedPosts);
          setUrl(null);
        }
      } catch (err) {
        console.error("Error fetching posts! " + err);
        toast.error("Error Fetching Posts!");
      }
    };
    fetchPosts();
  }, [search, url]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDialogRef.current &&
        !filterDialogRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  const filterPosts = () => {
    let filteredPosts = [...posts];

    if (recentlyPublished) {
      filteredPosts = filteredPosts.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (oldestArticles) {
      filteredPosts = filteredPosts.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (azSort) {
      filteredPosts = filteredPosts.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
    } else if (zaSort) {
      filteredPosts = filteredPosts.sort((a, b) =>
        b.title.localeCompare(a.title)
      );
    }

    return filteredPosts;
  };

  const currentPosts = filterPosts().slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    window.scroll(0, 0);
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    window.scroll(0, 0);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterChange = (e) => {
    switch (e.target.name) {
      case "recentlyPublished":
        setRecentlyPublished(e.target.checked);
        setOldestArticles(false);
        setAzSort(false);
        setZaSort(false);
        break;
      case "oldestArticles":
        setRecentlyPublished(false);
        setOldestArticles(e.target.checked);
        setAzSort(false);
        setZaSort(false);
        break;
      case "azSort":
        setRecentlyPublished(false);
        setOldestArticles(false);
        setAzSort(e.target.checked);
        setZaSort(false);
        break;
      case "zaSort":
        setRecentlyPublished(false);
        setOldestArticles(false);
        setAzSort(false);
        setZaSort(e.target.checked);
        break;
      default:
        break;
    }
    setShowFilter(false);
  };

  return (
    <>
      <div className="header-container-articles">
        <h1 className="header-title-articles">Articles</h1>
        <button
          className="filter-btn"
          onClick={toggleFilter}
          ref={filterButtonRef}
        >
          Filter{" "}
          <FontAwesomeIcon icon={faFilter} style={{ marginLeft: "10px" }} />
        </button>
      </div>

      {showFilter && (
        <div
          className="filter-dialog"
          style={{
            top:
              filterButtonRef.current?.offsetTop +
              filterButtonRef.current?.offsetHeight,
            left: filterButtonRef.current?.offsetLeft,
          }}
          ref={filterDialogRef}
        >
          <h2>Filter</h2>
          <div className="checkbox-group">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                name="recentlyPublished"
                checked={recentlyPublished}
                onChange={handleFilterChange}
              />
              <span className="checkmark"></span> Recently Published
            </label>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                name="oldestArticles"
                checked={oldestArticles}
                onChange={handleFilterChange}
              />
              <span className="checkmark"></span> Oldest Articles
            </label>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                name="azSort"
                checked={azSort}
                onChange={handleFilterChange}
              />
              <span className="checkmark"></span> A-Z
            </label>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                name="zaSort"
                checked={zaSort}
                onChange={handleFilterChange}
              />
              <span className="checkmark"></span> Z-A
            </label>
          </div>
        </div>
      )}

      <div className="home">
        <Posts posts={currentPosts} />
      </div>
      <div className="pagination">
        <button
          style={{
            width: "50px",
            height: "30px",
            backgroundColor: "#B1E5F2",
            borderRadius: "10px",
            border: "none",
            cursor: "pointer",
          }}
          className="pagination-left-btn"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <button
          style={{
            width: "50px",
            height: "30px",
            backgroundColor: "#B1E5F2",
            borderRadius: "10px",
            border: "none",
            cursor: "pointer",
          }}
          className="pagination-right-btn"
          onClick={nextPage}
          disabled={currentPosts.length < postsPerPage}
        >
          Next
        </button>
      </div>
    </>
  );
}
