import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import "./username.css";
import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function Username() {
  const [username, setUsername] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("user_id");
    setUserId(userId);
  }, [location]);

  const handleChange = async (e) => {
    const enteredUsername = e.target.value;
    setUsername(enteredUsername);

    try {
      const response = await axios.post("/users/checkUsername", {
        username: enteredUsername,
      });
      if (response.status !== 200) {
        toast.info(response.data.msg);
      }

      setUsernameAvailable(!response.data.exists); // Set true if username is available
    } catch (error) {
      console.error("Error checking username:", error);
      toast.error("Error Validating Username!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    try {
      const res = await axios.post("/users/addusername", {
        userId,
        username,
      });
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      res.data && window.location.replace(`/preference?user_id=${userId}`);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <div className="page-container-username">
      <div className="left-container-username">
        <Link to="/">
          <img
            src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/logo1.png"}
            alt="For Love of Writers Logo"
            className="logo-username"
          />
        </Link>
        <img
          src={
            process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL +
            "/bottom_image.jpg"
          }
          alt="Your Image"
          className="picture-username"
        />
      </div>
      <div className="right-container-username">
        <div className="container-username">
          <Header />
          <ul className="progressbar-username">
            <li className="done">
              <span>Sign Up</span>
            </li>
            <li className="active">
              <span>Username</span>
            </li>
            <li className="inactive">
              <span>Preference</span>
            </li>
            <li className="inactive">
              <span>The End</span>
            </li>
          </ul>
        </div>

        <form onSubmit={handleSubmit} className="user-form">
          <h1 style={{ fontSize: "25px", fontWeight: "500" }}>
            Choose your Username
          </h1>
          <p style={{ fontSize: "15px" }}>Go on, get creative!</p>
          <div className="input-container">
            <input
              type="text"
              value={username}
              onChange={handleChange}
              placeholder="Choose a Username"
              required
              className={`inputUser ${usernameAvailable ? 'username-available' : ''}`} // Class to ensure styling
            />
            {/* Checkmark icon if username is available */}
            {usernameAvailable && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="checkmark-icon"
              />
            )}
          </div>
          {usernameAvailable === false && username && (
            <p style={{ fontWeight: "400" }}>Username not available</p>
          )}
          <div className="next-container">
            <button
              className={`username-button next-button-username ${username ? "active" : "inactive"}`}
              disabled={!username}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <p>NEXT</p>
          </div>
          {error && (
            <span style={{ color: "red", marginTop: "10px" }}>
              Something went wrong!
            </span>
          )}
        </form>
      </div>
    </div>
  );
}
